import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import { memo, useId } from "react";

import { IconType } from "@repo/types/icon";
import Icon, { IconSize } from "@repo/ui/Icon";

import { MOON_VARIANTS, SUN_VARIANTS } from "./config";

import * as s from "./SwitchTheme.module.scss";

export type SwitchThemeProps = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Флаг активации переключателя. */
  checked?: boolean;
  /** Обработчик изменения значения переключателя. */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const SwitchTheme: React.FC<SwitchThemeProps> = ({
  className,
  checked,
  onChange,
  ...props
}: SwitchThemeProps) => {
  const id = useId();

  return (
    <div className={clsx(s["switch"], className)}>
      <input
        checked={checked}
        className={s["switch__input"]}
        id={id}
        type="checkbox"
        onChange={onChange}
        {...props}
      />

      <label className={s["switch__label"]} data-is-sun={checked} htmlFor={id}>
        <span className={s["switch__text"]}>Сменить тему</span>

        <motion.span
          layout
          className={s["switch__toggle"]}
          transition={{ duration: 0.5 }}
        >
          <AnimatePresence initial={false} mode="wait">
            <motion.div
              key={checked ? IconType.SUN : IconType.MOON}
              animate="default"
              className={s["switch__icon"]}
              exit="exit"
              initial="initial"
              transition={{ duration: 0.2 }}
              variants={checked ? SUN_VARIANTS : MOON_VARIANTS}
            >
              <Icon
                size={IconSize.S}
                type={checked ? IconType.SUN : IconType.MOON}
              />
            </motion.div>
          </AnimatePresence>
        </motion.span>
      </label>
    </div>
  );
};

export default memo(SwitchTheme);
