import { ActionKind } from "./config";
import {
  ActionCallback,
  ActionEntity,
  ActionExternal,
  ActionRoute,
} from "./types";

export function isActionCallback(
  action: ActionEntity,
): action is ActionCallback {
  return action.kind === ActionKind.CALLBACK;
}

export function isActionExternal(
  action: ActionEntity,
): action is ActionExternal {
  return action.kind === ActionKind.EXTERNAL;
}

export function isActionRoute(action: ActionEntity): action is ActionRoute {
  return action.kind === ActionKind.ROUTE;
}
