import clsx from "clsx";
import { observer } from "mobx-react-lite";

import { TabKey } from "@repo/data/config";
import GlobalTabNodeStore from "@repo/data/store/single/GlobalTabNodeStore";

import MenuGroup from "./MenuGroup";
import MenuItem from "./MenuItem";

import * as s from "./Menu.module.scss";

export type MenuProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

interface IMenuComponent extends React.FC<MenuProps> {
  /** Вложенный компонент MenuItem. */
  Item: typeof MenuItem;
  /** Вложенный компонент MenuGroup. */
  Group: typeof MenuGroup;
}

const Menu: IMenuComponent = ({ className }: MenuProps) => {
  const menuRoot = GlobalTabNodeStore.getInstance().useTab(TabKey.MENU);

  if (!menuRoot) {
    return null;
  }

  return (
    <div className={clsx(s["menu"], className)}>
      {menuRoot.nodes?.map((menuGroup) => (
        <Menu.Group key={menuGroup.key} title={menuGroup.title}>
          {menuGroup.nodes?.map((menuItem) => (
            <Menu.Item
              key={menuItem.key}
              action={menuItem.action}
              icon={menuItem.icon}
              title={menuItem.title}
            />
          ))}
        </Menu.Group>
      ))}
    </div>
  );
};

Menu.Item = MenuItem;
Menu.Group = MenuGroup;

export default observer(Menu);
