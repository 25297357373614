import { Link, type LinkProps } from "@tanstack/react-router";
import clsx from "clsx";
import { forwardRef, memo, useCallback } from "react";

import { IconType } from "@repo/types/icon";
import Counter, { CounterSize } from "@repo/ui/Counter";
import Icon, { IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./MenuMobileItem.module.scss";

export type MenuMobileItemProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Тип иконки. */
  iconType: IconType;
  /** Заголовок. */
  title: string;
  /** Ссылка. */
  linkTo?: LinkProps["to"] | string;
  /** Активность элемента. */
  open?: boolean;
  /** Предотвращение действия по умолчанию. */
  preventDefault?: boolean;
  /** Счетчик для элемента меню. */
  counter?: {
    /** Основное количество для отображения. */
    count: string;
    /** Дополнительное количество для отображения. */
    allCount?: string;
  };
  /** Обработчик клика. */
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const MenuMobileItem: React.FC<MenuMobileItemProps> = forwardRef<
  HTMLAnchorElement,
  MenuMobileItemProps
>(
  (
    {
      className,
      iconType,
      title,
      linkTo,
      open,
      preventDefault,
      counter,
      onClick,
      ...props
    }: MenuMobileItemProps,
    ref,
  ) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (preventDefault) {
          e.preventDefault();
          e.stopPropagation();
        }

        onClick?.(e);
      },
      [preventDefault, onClick],
    );

    return (
      <Link
        ref={ref}
        className={clsx(s["root"], open && s["root_active"], className)}
        to={linkTo}
        onClick={handleClick}
        {...props}
      >
        <Icon className={s["root__icon"]} size={IconSize.M} type={iconType} />

        <Typography
          Component="span"
          typographyType={TypographyType.CAPTION_SM}
          typographyWeight={TypographyWeight._500}
        >
          {title}
        </Typography>

        {counter && (
          <Counter
            allCount={counter?.allCount}
            className={s["root__counter"]}
            count={counter.count}
            size={CounterSize.S}
          />
        )}
      </Link>
    );
  },
);

MenuMobileItem.displayName = "MenuMobileItem";

export default memo(MenuMobileItem);
