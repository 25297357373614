// extracted by mini-css-extract-plugin
var _1 = "icon__kIOtf";
var _2 = "icon_button__LfaoC";
var _3 = "icon_direction-down__vPq4e";
var _4 = "icon_direction-left__RTbGc";
var _5 = "icon_direction-right__KsYfl";
var _6 = "icon_direction-up__ZxNNB";
var _7 = "icon_size-l__KDBet";
var _8 = "icon_size-m__atDGJ";
var _9 = "icon_size-s__nLgZl";
var _a = "icon_size-xl__oF96v";
var _b = "icon_size-xs__WJDvL";
export { _1 as "icon", _2 as "icon_button", _3 as "icon_direction-down", _4 as "icon_direction-left", _5 as "icon_direction-right", _6 as "icon_direction-up", _7 as "icon_size-l", _8 as "icon_size-m", _9 as "icon_size-s", _a as "icon_size-xl", _b as "icon_size-xs" }
