export enum ActionKind {
  /** При клике на вкладку нужно вызвать callback */
  CALLBACK = "CALLBACK",
  /** Ссылка для переход на внешний домен */
  EXTERNAL = "EXTERNAL",
  /** Ссылка для переход на внутренний route */
  ROUTE = "ROUTE",
}

export enum CallbackType {
  TAB_CLICK = "TAB_CLICK",
}
