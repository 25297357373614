import { Link } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

import { StoryType } from "@repo/data/store/single/GlobalStoriesStore";
import { IconType } from "@repo/types/icon";
import Button, { ButtonColor, ButtonTheme } from "@repo/ui/Button";
import Icon from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useModalStories from "@app/hooks/story/useModalStories";
import useWidth from "@app/hooks/useWidth";

import * as s from "./StoryItem.module.scss";

type Props = {
  /** Конфиг истории */
  item: StoryType;
};

const StoryItem: React.FC<Props> = ({ item }) => {
  const modalStories = useModalStories();

  const { isPhone } = useWidth();

  const handleClose = useCallback(() => {
    modalStories.handleClose();
  }, [modalStories]);

  return (
    <div
      className={s["root"]}
      style={{
        backgroundImage: `url(${item.cover})`,
      }}
    >
      <div className={s["root__content"]}>
        <div className={s["root__close"]} onClick={handleClose}>
          <Icon type={IconType.CLOSE} />
        </div>
        <Typography
          className={s["root__title"]}
          typographyType={isPhone ? TypographyType.H1 : TypographyType.H3}
          typographyWeight={TypographyWeight._700}
        >
          {item.title}
        </Typography>
        <Typography
          className={s["root__description"]}
          typographyType={
            isPhone ? TypographyType.BODY_MD : TypographyType.BODY_LG
          }
          typographyWeight={TypographyWeight._500}
        >
          {item.description}
        </Typography>
        <Link rel="noopener noreferrer" target="_blank" to={item.link}>
          <Button.Base
            className={s["root__button"]}
            color={ButtonColor.TERTIARY}
            theme={ButtonTheme.BORDERED}
          >
            Ознакомиться
          </Button.Base>
        </Link>
      </div>
    </div>
  );
};

export default observer(StoryItem);
