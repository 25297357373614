import clsx from "clsx";
import { memo, useCallback } from "react";

import { IconSize, type IconCommonProps } from "../Icon";

import * as s from "./IconSVG.module.scss";

export type IconSVGProps = {
  /** SVG в виде строки. */
  svgText: string;
} & IconCommonProps;

const IconSVG: React.FC<IconSVGProps> = ({
  className,
  svgText,
  size = IconSize.M,
  direction,
  disabled,
  onClick,
}: IconSVGProps) => {
  const classNames = clsx(
    s["icon"],
    size && s[`icon_size-${size}`],
    direction && s[`icon_direction-${direction}`],
    !disabled && onClick && s["icon_button"],
    className,
  );

  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (disabled || !onClick) {
        return;
      }

      onClick(e);
    },
    [disabled, onClick],
  );

  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgText }}
      className={classNames}
      onClick={handleClick}
    />
  );
};

export default memo(IconSVG);
