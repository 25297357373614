// extracted by mini-css-extract-plugin
var _1 = "icon__wdTrr";
var _2 = "icon__img__X4RTR";
var _3 = "icon_button__OHpLM";
var _4 = "icon_direction-down__dRytJ";
var _5 = "icon_direction-left__Xaag2";
var _6 = "icon_direction-right__tX39X";
var _7 = "icon_direction-up__Pl3Vf";
var _8 = "icon_size-l__gZx91";
var _9 = "icon_size-m__HEC_J";
var _a = "icon_size-s__GbDkh";
var _b = "icon_size-xl__W_C8a";
var _c = "icon_size-xs__SP1q6";
export { _1 as "icon", _2 as "icon__img", _3 as "icon_button", _4 as "icon_direction-down", _5 as "icon_direction-left", _6 as "icon_direction-right", _7 as "icon_direction-up", _8 as "icon_size-l", _9 as "icon_size-m", _a as "icon_size-s", _b as "icon_size-xl", _c as "icon_size-xs" }
