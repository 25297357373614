import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import Button, { ButtonColor } from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";
import Popover from "@repo/ui/Popover";

import useWidth from "@app/hooks/useWidth";

import ControlButtons from "./ControlButtons";

import * as s from "./ProfileControls.module.scss";

export type ProfileControlsProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const ProfileControls: React.FC<ProfileControlsProps> = ({
  className,
}: ProfileControlsProps) => {
  const { isPhone } = useWidth();

  return (
    <div className={clsx(s["profile-controls"], className)}>
      {isPhone ? (
        <Popover.Base modal offsetContent={8}>
          <Popover.Trigger>
            {(props) => {
              return (
                <Button.Text
                  className={s["profile-controls__toggle"]}
                  color={ButtonColor.DEFAULT}
                  {...props}
                >
                  <Icon size={IconSize.XS} type={IconType.DOTS} />
                </Button.Text>
              );
            }}
          </Popover.Trigger>

          <Popover.Content>
            <ControlButtons className={s["profile-controls__content"]} />
          </Popover.Content>
        </Popover.Base>
      ) : (
        <ControlButtons />
      )}
    </div>
  );
};

export default memo(ProfileControls);
