import { action, makeObservable, observable, runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import type { tab } from "@repo/api/types";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import type { IconEntity } from "@repo/types/icon";
import type { ActionEntity } from "@repo/types/tab";

import type { TabNodeType } from "./types";

class TabNodeModel implements TabNodeType {
  private readonly _meta = new MetaStore();

  key: string;
  title: string;
  icon: IconEntity;
  action: ActionEntity;
  value: Record<string, string>;
  parent: TabNodeModel | null;
  nodes: TabNodeModel[] | null;

  constructor(rawNode: tab.InterfaceTabTreeApi, parent?: TabNodeModel) {
    this.key = rawNode.key;
    this.title = rawNode.title;
    this.icon = rawNode.icon;
    this.action = rawNode.action;
    this.value = rawNode.value;
    this.parent = parent ?? null;

    if (!rawNode.children.length) {
      this.nodes = null;
    }

    this.nodes = rawNode.children.map((child) => new TabNodeModel(child, this));

    makeObservable<this>(this, {
      nodes: observable.ref,
      fetchTree: action.bound,
    });
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  async fetchTree(): Promise<void> {
    if (this.nodes?.length) {
      return;
    }

    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(
      ApiPath.INTERFACE_TAB_TREE,
      "GET",
      { data: { key: this.key } },
    );

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this.nodes = data.children.map((child) => new TabNodeModel(child, this));

      this._meta.setLoadedSuccessMeta();
    });
  }
}

export default TabNodeModel;
