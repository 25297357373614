import { makeObservable, observable, runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import { TabKey } from "@repo/data/config";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import TabNodeModel from "@repo/data/store/models/TabNodeModel";

type PrivateFields = "_tabNode";

class TabNodeStore {
  private readonly _meta = new MetaStore();

  private _tabNode: Map<string, TabNodeModel> = new Map();

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _tabNode: observable,
    });
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  useTab(key: TabKey): TabNodeModel | null {
    return this._tabNode.get(key) ?? null;
  }

  async fetchTabNode(): Promise<void> {
    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(
      ApiPath.INTERFACE_TAB_ROOT,
      "GET",
    );

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this._tabNode = data.items.reduce((acc, item) => {
        acc.set(item.key, new TabNodeModel(item));

        return acc;
      }, new Map());

      this._meta.setLoadedSuccessMeta();
    });
  }
}

export default TabNodeStore;
