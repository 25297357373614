export enum IconDirection {
  /** Верх */
  UP = "up",
  /** Низ */
  DOWN = "down",
  /** Право */
  RIGHT = "right",
  /** Лево */
  LEFT = "left",
}

export enum IconSize {
  /** 16px */
  XS = "xs",
  /** 20px */
  S = "s",
  /** 24px */
  M = "m",
  /** 32px */
  L = "l",
  /** 40px */
  XL = "xl",
}
