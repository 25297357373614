import clsx from "clsx";
import { memo, useCallback } from "react";

import { IconType } from "@repo/types/icon";
import Icon, { IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import AvatarImage, { AvatarImageProps } from "./AvatarImage";
import { AvatarAcceptTypes, AvatarSize } from "./config";

import * as s from "./Avatar.module.scss";

export type AvatarProps = AvatarImageProps & {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Мобильная версия. */
  isMobile: boolean;
  /** Типы файлов, которые можно загрузить. */
  accept?: AvatarAcceptTypes;
  /** Обработчик изменения файла. */
  onChange: (files: FileList) => void;
};

const Avatar: React.FC<AvatarProps> = ({
  className,
  isMobile,
  accept,
  onChange,
  ...props
}: AvatarProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        onChange(e.target.files);
      }

      e.target.value = "";
    },
    [onChange],
  );

  return (
    <div className={clsx(s["avatar"], className)}>
      <AvatarImage
        className={s["avatar__image"]}
        round={!isMobile}
        size={AvatarSize.UNSET}
        {...props}
      />

      <Typography
        Component="label"
        className={s["avatar__button"]}
        typographyType={TypographyType.CAPS_MD}
        typographyWeight={TypographyWeight._700}
      >
        <input
          accept={accept ?? "image/*"}
          className={s["avatar__input"]}
          type="file"
          onChange={handleChange}
        />

        {isMobile ? (
          <Icon size={IconSize.XS} type={IconType.PHOTO} />
        ) : (
          "Изменить"
        )}
      </Typography>
    </div>
  );
};

export default memo(Avatar);
