// extracted by mini-css-extract-plugin
var _1 = "icon__h_rEU";
var _2 = "icon_button__zYTBS";
var _3 = "icon_direction-down__o9DLj";
var _4 = "icon_direction-left__k1Y8P";
var _5 = "icon_direction-right__JPXVo";
var _6 = "icon_direction-up__Zkiqh";
var _7 = "icon_size-l__FB71R";
var _8 = "icon_size-m__JRKa2";
var _9 = "icon_size-s__MIe5b";
var _a = "icon_size-xl__sHJqc";
var _b = "icon_size-xs__s1Ayz";
export { _1 as "icon", _2 as "icon_button", _3 as "icon_direction-down", _4 as "icon_direction-left", _5 as "icon_direction-right", _6 as "icon_direction-up", _7 as "icon_size-l", _8 as "icon_size-m", _9 as "icon_size-s", _a as "icon_size-xl", _b as "icon_size-xs" }
