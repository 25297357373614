import clsx from "clsx";
import { memo, useCallback } from "react";

import { IconType } from "@repo/types/icon";
import Button, { type ButtonBaseProps } from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";

import { usePopoverContext } from "../../context";

import * as s from "./PopoverClose.module.scss";

export type PopoverCloseProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Обработчик клика на кнопку. */
  onClick?: ButtonBaseProps["onClick"];
};

const PopoverClose: React.FC<PopoverCloseProps> = ({
  className,
  onClick,
}: PopoverCloseProps) => {
  const { setOpen } = usePopoverContext();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(event);

      setOpen(false);
    },
    [onClick, setOpen],
  );

  return (
    <Button.Default
      className={clsx(s["button"], className)}
      onClick={handleClick}
    >
      <Icon size={IconSize.M} type={IconType.CLOSE} />
    </Button.Default>
  );
};

export default memo(PopoverClose);
