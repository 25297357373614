import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

import Button, { ButtonSize } from "@repo/ui/Button";
import { InputPassword, InputWithMask } from "@repo/ui/Input";
import { TypographyType } from "@repo/ui/Typography";
import WithError from "@repo/ui/WithError";

import useWidth from "@app/hooks/useWidth";
import { useAuthFormStore } from "@app/store/local/AuthFormStore";

import RecoverPassLink from "../RecoverPassLink";

import * as s from "./FormLogin.module.scss";

export type FormLoginProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const LoginForm: React.FC<FormLoginProps> = ({ className }: FormLoginProps) => {
  const { isPhone } = useWidth();
  const navigate = useNavigate();

  const authFormStore = useAuthFormStore();

  const handelLoginChange = useCallback(
    (value: string) => {
      authFormStore.setLoginMaskOptions(value);
      authFormStore.setLogin(value);
    },
    [authFormStore],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      await authFormStore.submit();

      navigate({
        to: "/",
      });
    },
    [authFormStore, navigate],
  );

  return (
    <WithError
      className={s["error"]}
      message={authFormStore.errorForm}
      typographyType={isPhone ? TypographyType.BODY_SM : TypographyType.BODY_LG}
    >
      <form className={clsx(s["form"], className)} onSubmit={handleSubmit}>
        <InputWithMask
          label={
            isPhone
              ? "Телефон, логин или почта"
              : "Номер телефона, логин или почта"
          }
          name="login"
          options={authFormStore.loginMaskOptions}
          value={authFormStore.login}
          onChange={handelLoginChange}
        />
        <InputPassword
          autoComplete="off"
          label={"Пароль"}
          name="password"
          value={authFormStore.password}
          onChange={authFormStore.setPassword}
        />

        <Button.Base
          className={s["form__submit"]}
          loading={authFormStore.meta.isLoading}
          size={isPhone ? ButtonSize.ADAPTIVE_MEDIUM : ButtonSize.LARGE}
          type="submit"
        >
          Войти
        </Button.Base>

        <RecoverPassLink link={"/"}>Восстановить пароль</RecoverPassLink>
      </form>
    </WithError>
  );
};

export default observer(LoginForm);
