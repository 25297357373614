import clsx from "clsx";
import { memo, PropsWithChildren } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./MenuGroup.module.scss";

export type MenuGroupProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Заголовок группы меню. */
  title?: string;
};

const MenuGroup: React.FC<PropsWithChildren<MenuGroupProps>> = ({
  className,
  title,
  children,
}: PropsWithChildren<MenuGroupProps>) => {
  return (
    <div className={clsx(s["menu-group"], className)}>
      {Boolean(title) && (
        <Typography
          Component="p"
          className={s["menu-group__title"]}
          typographyType={TypographyType.CAPS_MD}
          typographyWeight={TypographyWeight._700}
        >
          {title}
        </Typography>
      )}

      <div className={s["menu-group__items"]}>{children}</div>
    </div>
  );
};

export default memo(MenuGroup);
