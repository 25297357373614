import SingleStoreManager from "../SingleStoreManager";

import InterfaceRuleStore from "./InterfaceRuleStore";

const GlobalInterfaceRuleStore = SingleStoreManager.createStore(
  InterfaceRuleStore,
  { destroyed: true },
);

export default GlobalInterfaceRuleStore;
