import clsx from "clsx";
import React, { memo } from "react";

import { IconType } from "@repo/types/icon";
import Button from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";

import useWidth from "@app/hooks/useWidth";

import * as s from "./ModalStoriesNavigation.module.scss";

type Props = {
  /** Флаг, отключающий кнопку назад */
  isPrevDisabled: boolean;
  /** Флаг, отключающий кнопку вперед */
  isNextDisabled: boolean;
  /** Callback на нажатие кнопки назад */
  handlePrev: () => void;
  /** Callback на нажатие кнопки вперед */
  handleNext: () => void;
};

const ModalStoriesNavigation: React.FC<Props> = ({
  isPrevDisabled,
  isNextDisabled,
  handlePrev,
  handleNext,
}) => {
  const { isTablet } = useWidth();

  return (
    <div className={s["root"]}>
      <Button.Default
        className={clsx(
          s["root__button"],
          s["root__button_prev"],
          isPrevDisabled && s["root__button_disabled"],
        )}
        onClick={handlePrev}
      >
        <Icon size={IconSize.M} type={IconType.ARROW_RIGHT} />
      </Button.Default>
      <Button.Default
        className={clsx(
          s["root__button"],
          s["root__button_next"],
          isNextDisabled && s["root__button_disabled"],
        )}
        onClick={handleNext}
      >
        <Icon
          size={isTablet ? IconSize.L : IconSize.M}
          type={IconType.ARROW_RIGHT}
        />
      </Button.Default>
    </div>
  );
};

export default memo(ModalStoriesNavigation);
