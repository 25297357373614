import { memo } from "react";

import { IconType } from "@repo/types/icon";

import { ICON_ENTITIES } from "./components";
import type { IconBaseProps } from "./components/IconBase";

export type IconProps = {
  /** Тип иконки. */
  type: IconType;
} & IconBaseProps;

const Icon: React.FC<IconProps> = ({ type: iconType, ...props }: IconProps) => {
  const Component = ICON_ENTITIES[iconType];

  return <Component {...props} />;
};

export default memo(Icon);
