import { memo } from "react";

import { IconEntity, IconEntityKind } from "@repo/types/icon";

import Icon, { type IconCommonProps } from "../Icon";
import IconSVG from "../IconSVG";
import IconURL from "../IconURL";

export type IconComposeProps = {
  data: IconEntity;
} & IconCommonProps;

const IconCompose: React.FC<IconComposeProps> = ({
  data,
  ...props
}: IconComposeProps) => {
  if (data.kind === IconEntityKind.SVG) {
    return <IconSVG svgText={data.value} {...props} />;
  }

  if (data.kind === IconEntityKind.URL) {
    return <IconURL url={data.value} {...props} />;
  }

  return <Icon type={data.value} {...props} />;
};

export default memo(IconCompose);
