import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import { AvatarImage, AvatarSize } from "@repo/ui/Avatar";
import Button from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { EmployInfo } from "./types";

import * as s from "./CardEmploy.module.scss";

export type CardEmployProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Информация о сотруднике. */
  employ: EmployInfo;
  // /** Тип компонента. */
  // component?: "a" | "button";
  /** Иконка кнопки открытия. */
  iconType?: IconType;
  /** Мобильная версия. */
  isMobile: boolean;
};

// TODO Компонент должен принимать либо ссылку, либо кнопку
const CardEmploy: React.FC<CardEmployProps> = ({
  className,
  employ,
  iconType = IconType.EXTEND,
  isMobile,
}: CardEmployProps) => {
  return (
    <div className={clsx(s["root"], className)}>
      <AvatarImage
        round
        alt={employ.name}
        size={AvatarSize.S}
        src={employ.avatar}
      />

      <div className={s["root__content"]}>
        <div className={s["root__header"]}>
          <Typography
            Component="p"
            className={s["root__employ-name"]}
            typographyType={
              isMobile ? TypographyType.BODY_SM : TypographyType.BODY_LG
            }
            typographyWeight={TypographyWeight._700}
          >
            {employ.name}
          </Typography>

          <Typography
            Component="p"
            className={s["root__employ-position"]}
            typographyType={
              isMobile ? TypographyType.CAPTION : TypographyType.BODY_SM
            }
            typographyWeight={TypographyWeight._500}
          >
            {employ.position}
          </Typography>
        </div>

        <div className={s["root__details"]}>
          {employ.details.map((detail) => (
            <div key={detail.id} className={s["root__detail"]}>
              <Typography
                Component="p"
                className={s["root__detail-title"]}
                typographyType={
                  isMobile ? TypographyType.CAPTION : TypographyType.BODY_SM
                }
                typographyWeight={
                  isMobile ? TypographyWeight._500 : TypographyWeight._400
                }
              >
                {detail.title}
              </Typography>

              <Typography
                Component="p"
                className={s["root__employ-text"]}
                typographyType={
                  isMobile ? TypographyType.CAPTION : TypographyType.BODY_MD
                }
                typographyWeight={TypographyWeight._500}
              >
                {detail.text}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      <Button.Text className={s["root__button"]}>
        <Icon size={IconSize.M} type={iconType} />
      </Button.Text>
    </div>
  );
};

export default memo(CardEmploy);
