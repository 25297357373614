export enum LoaderSize {
  /** 36px */
  XS = "xs",
  /** 44px */
  S = "s",
  /** 66px */
  M = "m",
  /** 88px */
  L = "l",
}
