import React, { useState } from "react";

import { IconType } from "@repo/types/icon";
import Button, { ButtonSize } from "@repo/ui/Button";
import Icon from "@repo/ui/Icon";
import Link, { LinkColor } from "@repo/ui/Link";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import WidgetTransition from "../components/WidgetTransition";

import Chart from "./Chart/";
import ChartLegend, { ChartLegendItemEnum } from "./ChartLegend";
import MoneyValue, { MoneyValueSize } from "./MoneyValue";

import * as s from "./WidgetMoney.module.scss";

type Props = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const WidgetMoney: React.FC<Props> = ({ className }) => {
  const { isDesktop } = useWidth();

  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => setIsVisible((prev) => !prev);

  return (
    <WidgetTransition className={className}>
      <div className={s["root"]}>
        <div className={s["root__top"]}>
          <div className={s["root__top-left"]}>
            <Typography
              className={s["root__title"]}
              typographyType={isDesktop ? TypographyType.H3 : TypographyType.H2}
              typographyWeight={TypographyWeight._700}
            >
              Деньги
            </Typography>
            <Link
              color={LinkColor.PRIMARY}
              isMobile={!isDesktop}
              text="Посмотреть статистику"
              to="/profile"
            />
          </div>
          <div className={s["root__date"]}>
            <Icon className={s["root__icon"]} type={IconType.CALENDAR} />
            <Typography
              Component="span"
              typographyType={
                isDesktop ? TypographyType.CAPS_LG : TypographyType.CAPS_MD
              }
              typographyWeight={TypographyWeight._700}
            >
              Март 2023
            </Typography>
          </div>
        </div>

        <div className={s["root__chart-wrapper"]}>
          <Chart
            data={[
              {
                name: ChartLegendItemEnum.INCOME,
                value: 32032,
              },
              {
                name: ChartLegendItemEnum.EXPENSE,
                value: Math.abs(-5735.77),
              },
            ]}
          />
          <div className={s["root__balance-wrapper"]}>
            <Typography
              typographyType={
                isDesktop ? TypographyType.H4 : TypographyType.CAPTION
              }
              typographyWeight={TypographyWeight._500}
            >
              Начисления
            </Typography>
            <MoneyValue
              isVisible={isVisible}
              size={MoneyValueSize.MEDIUM}
              value={71336.5}
            />
            <Button.Base
              before={
                <Icon
                  className={s["root__button-icon"]}
                  type={isVisible ? IconType.EYE_OPEN : IconType.EYE_CLOSE}
                />
              }
              className={s["root__button"]}
              size={isDesktop ? ButtonSize.ADAPTIVE_SMALL : ButtonSize.SMALL}
              onClick={handleClick}
            >
              {isVisible ? "Скрыть цифры" : "Показать цифры"}
            </Button.Base>
          </div>
        </div>
        <ChartLegend isVisible={isVisible} />
      </div>
    </WidgetTransition>
  );
};

export default WidgetMoney;
