// extracted by mini-css-extract-plugin
var _1 = "bob__iuq6D";
var _2 = "hand__FnTlz";
var _3 = "loader__fC4gT";
var _4 = "loader__bob__UuYfS";
var _5 = "loader__hand__cL1MN";
var _6 = "loader__icon__Wp5zP";
var _7 = "loader_absolute__TNxkR";
var _8 = "loader_size-l__gOlQP";
var _9 = "loader_size-m__QSZDO";
var _a = "loader_size-s__CPwud";
var _b = "loader_size-xs__HH3in";
export { _1 as "bob", _2 as "hand", _3 as "loader", _4 as "loader__bob", _5 as "loader__hand", _6 as "loader__icon", _7 as "loader_absolute", _8 as "loader_size-l", _9 as "loader_size-m", _a as "loader_size-s", _b as "loader_size-xs" }
