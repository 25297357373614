import { Link, type LinkProps } from "@tanstack/react-router";
import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import { IconType } from "@repo/types/icon";
import Icon from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./RecoverPassLink.module.scss";

export type RecoverPassLinkProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Ссылка для перехода. */
  link: LinkProps["to"];
  /** Иконка для ссылки. */
  iconType?: IconType;
  /** Отключенное состояние. */
  disabled?: boolean;
};

const RecoverPassLink: React.FC<PropsWithChildren<RecoverPassLinkProps>> = ({
  className,
  link,
  iconType = IconType.LOCK_KEY,
  disabled,
  children,
}: PropsWithChildren<RecoverPassLinkProps>) => {
  return (
    <Typography
      Component={Link}
      className={clsx(s["link"], disabled && s["link_disabled"], className)}
      disabled={disabled}
      to={link}
      typographyType={TypographyType.CAPS_MD}
      typographyWeight={TypographyWeight._700}
    >
      <Icon className={s["link__icon"]} type={iconType} />

      {children}
    </Typography>
  );
};

export default memo(RecoverPassLink);
