import { makeObservable, observable } from "mobx";

import { RuleKey } from "@repo/data/config";
import InterfaceRuleModel, {
  type InterfaceRuleType,
} from "@repo/data/store/models/InterfaceRuleModel";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";

export class InterfaceRuleStore {
  private readonly _meta = new MetaStore();

  private _rules: Map<RuleKey, InterfaceRuleType> = new Map();

  constructor() {
    makeObservable<this, "_rules">(this, {
      _rules: observable.shallow,
    });
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  useRule(key: RuleKey): InterfaceRuleType | null {
    return this._rules.get(key) ?? null;
  }

  async getRules(keys: RuleKey | RuleKey[]): Promise<void> {
    this._meta.setLoadingStart();

    try {
      if (keys instanceof Array) {
        const promises = keys.map((key) => this._fetchRuleModel(key));
        await Promise.all(promises);

        this._meta.setLoadedSuccessMeta();
        return;
      }

      await this._fetchRuleModel(keys);
      this._meta.setLoadedSuccessMeta();
    } catch (e) {
      this._meta.setLoadedErrorMeta(e);
    }
  }

  private async _fetchRuleModel(key: RuleKey): Promise<void> {
    if (this._rules.has(key)) {
      return;
    }

    const ruleModel = new InterfaceRuleModel(key);
    await ruleModel.fetchRule();

    if (ruleModel.rule) {
      this._rules.set(key, ruleModel.rule);
    }
  }
}

export default InterfaceRuleStore;
