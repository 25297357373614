import clsx from "clsx";
import React, { useCallback, useRef, useState } from "react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./WidgetSwiperPagination.scss";

import { IconType } from "@repo/types/icon";
import Button from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";

import StoryItem from "./components/StoryItem";
import { WIDGET_CAROUSEL_HISTORY_CONFIG } from "./config";

import * as s from "./WidgetCarouselStories.module.scss";

const WidgetCarouselStories: React.FC = () => {
  const sliderRef = useRef<SwiperRef>(null);

  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const handlePrev = useCallback(() => {
    sliderRef.current?.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    sliderRef.current?.swiper.slideNext();
  }, []);

  return (
    <div className={s["root"]}>
      <Swiper
        ref={sliderRef}
        className={s["root__swiper"]}
        modules={[Navigation, Pagination]}
        navigation={{
          enabled: true,
          prevEl: null,
          nextEl: null,
        }}
        pagination={{
          enabled: true,
          modifierClass: "widget-swiper-pagination-",
        }}
        slidesPerView="auto"
        spaceBetween={15}
        onSnapIndexChange={(e) => {
          setIsBeginning(e.isBeginning);
          setIsEnd(e.isEnd);
        }}
      >
        {WIDGET_CAROUSEL_HISTORY_CONFIG.map((item) => (
          <SwiperSlide key={item.id} className={s["root__slide"]}>
            <StoryItem item={item} />
          </SwiperSlide>
        ))}
        <Button.Default
          className={clsx(
            s["root__navigation"],
            s["root__navigation_prev"],
            isBeginning && s["root__navigation_disabled"],
          )}
          onClick={handlePrev}
        >
          <Icon size={IconSize.M} type={IconType.ARROW_RIGHT} />
        </Button.Default>
        <Button.Default
          className={clsx(
            s["root__navigation"],
            s["root__navigation_next"],
            isEnd && s["root__navigation_disabled"],
          )}
          onClick={handleNext}
        >
          <Icon size={IconSize.M} type={IconType.ARROW_RIGHT} />
        </Button.Default>

        {/** Компенсируется отступ для пагинации */}
        <div className={s["root__pagination-placeholder"]} />
      </Swiper>
    </div>
  );
};

export default WidgetCarouselStories;
