import clsx from "clsx";
import { memo, useCallback } from "react";

import { type IconCommonProps, IconSize } from "../Icon";

import * as s from "./IconURL.module.scss";

export type IconURLProps = {
  url: string;
} & IconCommonProps;

const IconURL: React.FC<IconURLProps> = ({
  className,
  url,
  size = IconSize.M,
  direction,
  disabled,
  onClick,
}: IconURLProps) => {
  const classNames = clsx(
    s["icon"],
    size && s[`icon_size-${size}`],
    direction && s[`icon_direction-${direction}`],
    !disabled && onClick && s["icon_button"],
    className,
  );

  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (disabled || !onClick) {
        return;
      }

      onClick(e);
    },
    [disabled, onClick],
  );

  return (
    <div className={classNames} onClick={handleClick}>
      <img className={s["icon__img"]} src={url} />
    </div>
  );
};

export default memo(IconURL);
