import { action, makeObservable, observable } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";

import SingleStoreManager from "../SingleStoreManager";

import { AuthStage } from "./config";

type PrivateFields = "_stage";

class AuthStore {
  private _stage: AuthStage;

  get stage(): AuthStage {
    return this._stage;
  }

  constructor() {
    this._stage = AuthStage.UNAUTHENTICATED;

    makeObservable<this, PrivateFields>(this, {
      _stage: observable,

      setStage: action.bound,
      login: action.bound,
      logout: action.bound,
    });
  }

  setStage(value: AuthStage): void {
    this._stage = value;
  }

  login(): void {
    this.setStage(AuthStage.AUTHENTICATED);
  }

  async logout(): Promise<void> {
    await apiWithChecks(ApiPath.USER_LOGOUT, "GET");

    this.setStage(AuthStage.UNAUTHENTICATED);

    SingleStoreManager.destroyStores();
  }
}

export default AuthStore;
