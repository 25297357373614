import { Link } from "@tanstack/react-router";
import clsx from "clsx";
import { motion, Variants } from "framer-motion";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import { AvatarImage, AvatarSize } from "@repo/ui/Avatar";
import Icon, { IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./ProfileLink.module.scss";

export type ProfileLinkProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Имя пользователя. */
  username: string;
  /** URL аватара пользователя. */
  avatar: string;
};

const linkVariants: Variants = {
  tap: { scale: 0.98 },
};

const ProfileLink: React.FC<ProfileLinkProps> = ({
  className,
  username,
  avatar,
}: ProfileLinkProps) => {
  return (
    <Link className={clsx(s["profile"], className)} to={"/profile"}>
      <motion.div
        className={s["profile__container"]}
        variants={linkVariants}
        whileTap="tap"
      >
        <div className={s["profile__wrapper"]}>
          <Icon
            className={s["profile__icon"]}
            size={IconSize.M}
            type={IconType.ARROW_DOWN}
          />

          <Typography
            Component="p"
            className={s["profile__username"]}
            typographyType={TypographyType.BODY_MD}
            typographyWeight={TypographyWeight._700}
          >
            {username}
          </Typography>
        </div>

        <AvatarImage
          round
          alt={username}
          className={s["profile__avatar"]}
          size={AvatarSize.M}
          src={avatar}
        />
      </motion.div>
    </Link>
  );
};

export default memo(ProfileLink);
