import { Link } from "@tanstack/react-router";
import { useMemo } from "react";

import {
  type ActionEntity,
  isActionExternal,
  isActionRoute,
} from "@repo/types/tab";

type useTabActionType = ["a" | typeof Link | "div", Record<string, string>];

export const useTabAction = (action: ActionEntity): useTabActionType => {
  return useMemo(() => {
    if (isActionExternal(action)) {
      return [
        "a",
        {
          href: action.url,
          target: "_blank",
          rel: "noopener noreferrer",
        },
      ];
    }

    if (isActionRoute(action)) {
      return [
        Link,
        {
          to: action.route,
        },
      ];
    }

    return ["div", {}];
  }, [action]);
};
